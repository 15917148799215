import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiEndpoints , ApiEndpoints as API } from '../../../config/api-endpoints';
import { SiteConfig } from '../../../config/site-config';
import { LoginService } from 'src/app/services/login.service';
import { environment as ENV } from '../../../environments/environment'

@Injectable()
export class AuthService {
  private loggedIn = false;
  private _redirectUrl = '';
  private readonly ACCESS_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'refresh_token';
  private readonly USERNAME = 'userid';
  private rfTokenInterface = {
    userName: "",
    roleId: ""
  }
  constructor(private http: HttpClient,
              private jwtHelper: JwtHelperService,
              // private permissionsService: NgxPermissionsService,
              private router: Router,
              private loginService: LoginService) {
  }

  /**
   * Gets the value indicating whether a user is authenticated.
   *
   * @returns {boolean} True if the user is logged in; otherwise, false.
   */
  public get isAuthenticated() {
    const token = localStorage.getItem(SiteConfig.storageKey.accessToken);

    return !!token && !this.jwtHelper.isTokenExpired(token);
  }

  public get isTokenExpired() {
    const token = localStorage.getItem(SiteConfig.storageKey.accessToken);

    return !!token && this.jwtHelper.isTokenExpired(token);
  }

  getAccessToken() {
    return sessionStorage.getItem(this.ACCESS_TOKEN);
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.USERNAME);
  }

  /**
   * Gets the redirect URL.
   * This value is set by the login-guard to keep the URL a user is trying to access.
   * Later, it can be retrieved and the system can then redirect the user to the URL.
   *
   * @returns {string} The redirect URL.
   */
  public get redirectUrl() {
    return this._redirectUrl;
  }

  /**
   * Sets the redirect URL.
   * This value is set by the logged-in-guard to keep the URL a user is trying to access.
   * Later, it can be retrieved and the system can then redirect the user to the URL.
   *
   * @param url The redirect URL.
   */
  public set redirectUrl(url: string) {
    this._redirectUrl = url;
  }

  refreshToken() {
    this.rfTokenInterface.userName = this.getRefreshToken();
    if (JSON.parse(sessionStorage.getItem('userDetails'))?.type == 'tfcp' 
      || JSON.parse(sessionStorage.getItem('userDetails'))?.type == 'tccp'
      || JSON.parse(sessionStorage.getItem('userDetails'))?.internalUser === true
      || JSON.parse(sessionStorage.getItem('userDetails'))?.type == 'my-choice'
      || JSON.parse(sessionStorage.getItem('userDetails'))?.type == 'customized-claims-portal'
      || JSON.parse(sessionStorage.getItem('userDetails'))?.type == 'self-service-portal') {
      this.rfTokenInterface.roleId = '6';
    } else {
      this.rfTokenInterface.roleId = JSON.parse(sessionStorage.getItem('userDetails'))?.userRoleID;
    }
    return this.http.post<any>(`${ENV.baseUrl.api}${API.getFreshToken}`, this.rfTokenInterface).pipe(
      tap(
        tokens => {
          this.storeAccessToken(tokens.accessToken);
        }
      )
    );
  }

  storeAccessToken(token: string){
    sessionStorage.setItem(this.ACCESS_TOKEN, token);
  }

  private clearAllData() {
    this.loggedIn = false;
    sessionStorage.clear();
    localStorage.clear();
    this.redirectUrl = null;
  }
}
