import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiEndpoints as API } from './../../config/api-endpoints';
import { environment as ENV } from './../../environments/environment';
import { retry, tap } from "rxjs/operators";
import { PaymentMethod } from "../shared/models/payment-method.interface";
import { UserService } from "./user.service";
import { PolicyService } from "./policy.service";
import { LoginService } from "./login.service";




@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private currentInvoices = [];
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private policyService: PolicyService,
    private loginService: LoginService
  ) { }


  tokenProcessing(paylaod: any) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.BILLING_TOKEN_PROCESSING}`, paylaod, this.options);
  }

  billingCenterSearch(policy: string, source: string = 'gw'): Observable<any> {
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    let invoiceType = `invoiceType=${source}`;
    if (shopifyUID !== null) {
      return this.http.get<any>(`${ENV.baseUrl.api}${API.BILLING_CENTER_SEARCH}/${policy}?objectUID=${shopifyUID}&${invoiceType}`, this.options);
    }
    else {
      return this.http.get<any>(`${ENV.baseUrl.api}${API.BILLING_CENTER_SEARCH}/${policy}?${invoiceType}`, this.options);
    }
  }

  invoiceDownload(invoice): Observable<any> {
    let options = { 'responseType': 'arrayBuffer' as 'json', 'observe': 'response' as 'body' }  //Observe allows us to see the full response, including the headers, so we can get document type.
    return this.http.get(`${ENV.baseUrl.api}${API.DOWNLOAD_BC_DOCUMENT}/${invoice}`, options);
  }

  getUid(request: any, cancelUrl?, returnUrl?) {
    let requestPayload = {
      "contentTemplateUrl": ENV.hpp.contentTemplateUrl,
      "cancelUrl": ENV.hpp.cancelUrl,
      "returnUrl": ENV.hpp.returnUrl,
      "cssUrl": ENV.hpp.cssUrl,
      "hostedTokenize": "store_only",
      // "allowedTypes":"Visa|MasterCard|American Express",
      "required": "all",
      // "collectAddress": 1,
      "maxUserRetries": "5",
      "orderDescription": "ONLPaymentMethodStore",
      ...request
    }

    if (cancelUrl && returnUrl) {
      requestPayload.cancelUrl = cancelUrl;
      requestPayload.returnUrl = returnUrl;
    }

    return this.http.post<{ uid: string }>(`${ENV.baseUrl.api}${API.ORDER_ABSTRACTION}`, requestPayload, this.options).pipe(
      retry(3)
    );
  }

  retrievePaymentProfile(policyNumber: string) {
    let tokenType = this.userService.isDeliveryDefenseUser() ? 'cbp' : 'gw';
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.get<PaymentMethod>(`${ENV.baseUrl.api}${API.RETRIEVE_PAYMENT_PROFILE}/${policyNumber}?objectUID=${shopifyUID}&tokenType=${tokenType}`);
    }
    else {
      return this.http.get<PaymentMethod>(`${ENV.baseUrl.api}${API.RETRIEVE_PAYMENT_PROFILE}/${policyNumber}?tokenType=${tokenType}`);
    }
  }

  savePaymentProfile(paylaod: PaymentMethod, tokenType:string = 'gw') {
    paylaod['tokenType'] = tokenType; //new field defaulting to gw
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SAVE_PAYMENT_PROFILE}`, paylaod, this.options);
  }

  //Only saves the payment token. Does not send to GW for any payments or recurring.
  savePaymentToken(payload) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SAVE_PAYMENT_TOKEN}`, payload, this.options);
  }

  getAllPaymentTokens(policyNumber) {
    return this.http.get<any>(`${ENV.baseUrl.api}${API.GET_ALL_PAYMENT_TOKENS}/${policyNumber}`);
  }

  removePaymentProfile(paylaod: PaymentMethod, tokenType:string = 'gw') {
    paylaod['tokenType'] = tokenType; //new field defaulting to gw
    return this.http.post<any>(`${ENV.baseUrl.api}${API.REMOVE_PAYMENT_PROFILE}`, paylaod, this.options);
  }

  getCurrentInvoices() {
    return this.currentInvoices;
  }
  setCurrentInvoices(invoices: any[]) {
    this.currentInvoices = invoices;
  }

  DPATTokenProcessing(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.BILLING_TOKEN_PROCESSING}`, request, this.options);
  }

  getTHistoryAggregates(policyNumber: string) {
    let request = {
      policyNum: policyNumber
    }
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_COC_AGGREGATE}`, request, this.options);
  }

  getTHistoryTrackingNumberList(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_COC_LIST_OF_TRACKING_NUMBERS}`, request, this.options);
  }

  getTHistoryFullTrackingNumberData(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_COC_TRACKING_NUMBER_FULL_INFO}`, request, this.options);
  }

  getCCTrackingNumberData(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_COC_LIST_OF_TRACKING_NUMBERS}`, request, this.options);
  }

  getCCTrackingNumberDetails(sortColu: string, isAscs: boolean, isInvoiced: boolean, claimIds: string[]) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_COC_TRACKING_NUMBER_FULL_INFO}`, { trackingNumbers: claimIds, sortColumn: sortColu, isInvoiced: isInvoiced, isAsc: isAscs }, this.options);
  }
  
  DPAT_GET_RTP_HOSTED_PAGE(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DPAT_ORDER_ABSTRACTION}`, request, this.options);
  }

  DPATCheckIfEnrolled(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DPAT_RETRIEVE}`, request, this.options);
  }

  _billingData = new BehaviorSubject(null);

  get billingData() {
    return this._billingData;
  }

  getDPATScenarioType() {
    // This function only accounts for US and CA users (no DPAT functionality for EU)
    if (this.userService.isUsaUser()) {
      if ((this.userService.isDeliveryDefenseUser()) || (this.userService.isUSPSUser())) { // User is a DD User with ONLY DD (cbp) invoices (only Billing Tab is visible)
        return 'cbp';
      } else if (this.policyService.hasDeliveryDefense()) { // User is a DD User with BOTH Policy (gw) and DD (cbp) invoices
        return 'gwcbp';
      } else { // User is NOT a DD User, and has ONLY Policy (gw) invoices
        return 'gw';
      }
    } else { // Canada users do not need to differentiate between gw/cbp/gwcbp, so pass 'CA' for the scenario field.
      return 'CA';
    }
  }

  getDPATpcAccountNumber() {
      // This function only accounts for US and CA users (no DPAT functionality for EU)
    let policyDetails = this.loginService.getPolicyDetails();
    let policyNumber = this.userService.getUserInfo().policyNumber;
    let pcAccountNumber = '';
    
    if (this.userService.isUsaUser()) {
      if (this.userService.isDeliveryDefenseUser()) { // User is a DD User with ONLY DD (cbp) invoices (only Billing Tab is visible)
        pcAccountNumber = policyNumber ? policyNumber.split('-')[1] : ''; // this returns the shortened contract number
      } else if (this.policyService.hasDeliveryDefense()) { // User is a DD User with BOTH Policy (gw) and DD (cbp) invoices
        pcAccountNumber = policyDetails?.accountNumber ? policyDetails.accountNumber : ''; // this returns the gw pc account number
      } else { // User is NOT a DD User, and has ONLY Policy (gw) invoices
        pcAccountNumber = policyDetails?.accountNumber ? policyDetails.accountNumber : ''; // this returns the gw pc account number
      }
    } else { // Canada users are not required to pass a pcAccountNumber, as per current Moneris functionality
      pcAccountNumber = '';
    }

    return pcAccountNumber;
  }

  DWgetSummary(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DW_GetSummary}`, request, this.options).pipe(tap(data => {
      if (data?.data?.wallet) {
        sessionStorage.setItem('dwDetails', JSON.stringify(data.data.wallet));
      }
    }))
  }

  DWstartSession(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DWstartSession}`, request, this.options);
  }

  DWpostIndicators(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DWpostIndicators}`, request, this.options); 
  }

  DWgetIndicators(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DWgetIndicators}`, request, this.options); 
  }
}