import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { IStep } from '../../shared/components/stepper/models/step.interface';
import { UtilityService } from '../../services/utility.service';
import { BehaviorSubject, Subscription, forkJoin, observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ILogger } from '../../shared/models/logger.interface';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IEmail, emptyIEmail } from 'src/app/shared/models/email.interface';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { AccountService } from 'src/app/services/account.service';
import { environment as ENV } from '../../../environments/environment';
import en from 'src/assets/i18n/en.json';
import enGb from 'src/assets/i18n/en-GB.json';
import enCa from 'src/assets/i18n/en-CA.json';
import enFr from 'src/assets/i18n/en-FR.json';
import frFr from 'src/assets/i18n/fr-FR.json';
import enDe from 'src/assets/i18n/en-DE.json';
import deDe from 'src/assets/i18n/de-DE.json';
import enIt from 'src/assets/i18n/en-IT.json';
import itIt from 'src/assets/i18n/it-IT.json';
import { UserService } from 'src/app/services/user.service';
import { SiteConfig } from 'src/config/site-config';
import { TaggingService } from 'src/app/tags/tagging.service';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { ALPHA_NUMERIC_AND_SPACES_PATTERN } from 'src/app/shared/regex-patterns';

@Component({
  selector: 'upsc-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  //Forms
  formGroup: UntypedFormGroup;
  accountFormGroup: UntypedFormGroup;
  multiPolicyFormGroup: UntypedFormGroup;
  confirmFormGroup: UntypedFormGroup;
  reviewFormGroup: UntypedFormGroup;

  //Display helpers
  hide = true;
  hide2 = true;
  isLinear = true;
  isVisible = false;
  verificationPass = false;
  check = true;
  systemError = false;
  errorReturned: boolean = false;
  codeErrorReturned: boolean = false;
  registerErrorReturned: boolean = false;
  tempUserNotFound: boolean = false;
  showiPhoneTips: boolean = false;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isChecked = false;
  showAdminToggle = false;
  allowedToEarnDWPoints: boolean = false;
  apiInProgress = false;
  scriptError: boolean = false;
  dataError: boolean = false;
  userDetails;
  policyDetails;
  isTempUser: boolean = false;
  showMultiPolicy: boolean = false;
  showPolicy3: boolean = false;
  showAddPolicy3: boolean = true;
  arrayOfLogics = [];
  multiPolicySaveHadAnError = false;
  isFlexAppUser: boolean;
  //Data
  steps: IStep[];
  currentStep = 1;
  currentStepText = '';
  logInterface: ILogger = {};
  thisYear = (new Date()).getFullYear();
  existingObjectID: string;
  existingUserName: string;
  private emailModel: IEmail = emptyIEmail;
  verificationInterface = { emailId: "" };
  createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  placeholderTempUserID: any;
  subscriptions: Subscription[] = [];

  //Links
  privacyLink = ENV.links.privacyNotice;
  canadaprivacyLink = ENV.links.canadaprivacyNotice;
  ukprivacyNotice = ENV.links.ukprivacyNotice;
  deprivacyNotice = ENV.links.deprivacyNotice;
  itprivacyNotice = ENV.links.itprivacyNotice;
  frprivacyNotice = ENV.links.frprivacyNotice;

  saveUser = {
    userId: "",
    policyNumber: "",
    zipCode: ""
  };

  termsRequest = {
    userId: "",
    termId: "1",
    accepted: "T",
    system: "ONL"
  };

  user = {
    userObjectId: "",
    policyNumber: "",
    emailAddress: "",
    zipCode: "",
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    userRole: "",
    appRoleId: "",
    approved: false,
    emailFor: `${ENV.createRequest.emailFor}`,
    roleName: ""
  };

  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;

  constructor (
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private userService: UserService,
    private utilityService: UtilityService,
    private accountService: AccountService,
    private router: Router,
    private taggingService: TaggingService,
    private route: ActivatedRoute,
    private billingService: BillingService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private scriptService: ScriptService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.userDetails = this.userService.getUserInfo();
    this.policyDetails = this.loginService.getPolicyDetails();

    this.route.queryParams.subscribe(params => {
      if (params) {
        this.placeholderTempUserID = params.tempUserID;
        if ((this.placeholderTempUserID) && (this.isUsaUser)) {
          sessionStorage.setItem('tempUserId', this.placeholderTempUserID);
        }
        else if(params.type == 'flexApp') {
          this.isFlexAppUser = true;
        }
        this.router.navigate(['/create-account']);
      }
    });

    this.determineLocale();

    this.accountFormGroup = this.formBuilder.group({
      policyNumberFormControl: ['', [Validators.required, Validators.minLength(6)]],
      emailFormControl: ['', Validators.compose([Validators.required, Validators.email])],
      zipFormControl: ['', this.isCanadaUser ? [Validators.required,
      Validators.pattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)]
        : this.isUkUser ? [Validators.required, Validators.pattern(/^[A-Za-z0-9\s]{2,8}$/)]
          : [Validators.required, Validators.pattern(/\d{5}/)]],
    });

    this.confirmFormGroup = this.formBuilder.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fifthDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });

    this.reviewFormGroup = this.formBuilder.group({
      firstNameFormControl: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z]+$/)])],
      lastNameFormControl: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z]+$/)])],
      passwordFormControl: ['', Validators.compose([Validators.required, this.isCanadaUser ? Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : (this.isUkUser || this.isUsaUser || this.isGermanyUser || this.isItalyUser || this.isFranceUser) ? Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/) : Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{16,45}$/)])],
      userIdFormControl: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern("^[a-zA-Z0-9]*$")]],
      reenterPasswordFormControl: ['', Validators.required],
      checkboxFormControl: ['', [(control) => {
        return !control.value ? { 'required': true } : null;
      }]],
      adminControl: ['']
    });

    this.multiPolicyFormGroup = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      policy1: ['', [Validators.required, Validators.minLength(6)]],
      policy2: ['', [Validators.required, Validators.minLength(6)]],
      policy3: [''],
      zip1: ['', [Validators.required, Validators.pattern(ALPHA_NUMERIC_AND_SPACES_PATTERN)]],
      zip2: ['', [Validators.required, Validators.pattern(ALPHA_NUMERIC_AND_SPACES_PATTERN)]],
      zip3: ['']
    });

    this.formGroup = this.formBuilder.group({
      first: this.accountFormGroup,
      second: this.confirmFormGroup,
      third: this.reviewFormGroup
    });

    this.accountFormGroup.controls.emailFormControl.valueChanges.subscribe(val => {
      if (val == null || val == undefined) {

      } else {
        var atIndex = val.indexOf('@');
        if (atIndex == -1) {
        } else {
          //Check if there is a period after the @
          var dotIndex = val.substring(atIndex).indexOf('.');
          if (dotIndex == -1) {
            this.accountFormGroup.controls['emailFormControl'].setErrors({ 'invalid': true });
          }
        }
      }
    });

    this.reviewFormGroup.controls.userIdFormControl.valueChanges.subscribe(val => {
      if (val == this.reviewFormGroup.controls.passwordFormControl.value) {
        this.reviewFormGroup.controls.passwordFormControl.setErrors({ 'invalid': true });
      }
      if (val !== this.reviewFormGroup.controls.passwordFormControl.value) {
        //Next line just re-checks the validator. If everything is fine, it will remove the errors automatically
        this.reviewFormGroup.controls.passwordFormControl.updateValueAndValidity();
      }
    });

    this.reviewFormGroup.controls.passwordFormControl.valueChanges.subscribe(val => {
      if (val == null || val == undefined) {
      } else {
        //Password cannot be the same as the UserID
        if (val.includes(this.reviewFormGroup.controls.userIdFormControl.value)) {
          this.reviewFormGroup.controls.passwordFormControl.setErrors({ 'invalid': true });
        }
        //Password must be same as Re-entered password
        if (val !== this.reviewFormGroup.controls.reenterPasswordFormControl.value) {
          this.reviewFormGroup.controls.reenterPasswordFormControl.setErrors({ 'notEquivalent': true });
        }
        //If password = re-entered password, no errors should be on re-enter password
        if (val == this.reviewFormGroup.controls.reenterPasswordFormControl.value) {
          this.reviewFormGroup.controls.reenterPasswordFormControl.setErrors(null);
        }
      }
    });

    this.reviewFormGroup.controls.reenterPasswordFormControl.valueChanges.subscribe(val => {
      //If re-entered password doesn't match password, set errors
      if (val !== this.reviewFormGroup.controls.passwordFormControl.value) {
        this.reviewFormGroup.controls.reenterPasswordFormControl.setErrors({ 'notEquivalent': true });
      }
    });

    let tempUserId = sessionStorage.getItem('tempUserId');
    if (tempUserId) {
      this.isTempUser = true;
      this.showAdminToggle = false;
      this.apiInProgress = true;
      this.getTempUserDetails(tempUserId);

    } else { //continue flow as usual for normal users
      this.isTempUser = false;
      this.currentStep = 1;
    }

  } //End of constructor


  //Used in Stepper component - Use case: User selects green number to go backwards
  setCurrentStepManually(order: number) {
    if (this.currentStep >= order) {
      this.currentStep = order;
      this.currentStepText = this.steps.find(x => x.order === order).label;
      this.setActiveStep();
    }
  }

  setCurrentStepMobile(order: number) {
    if (this.currentStep >= order && this.currentStep > 1 && this.currentStep <= this.steps.length) {
      this.currentStep = order;
      this.currentStepText = this.steps.find(x => x.order === order).label;
      this.setActiveStep();
    }
  }

  techAgreementLink = ENV.links.techAgreement;
  reinvitedUserFlow: boolean = false;

  getTempUserDetails(tempUserId) {
    this.reinvitedUserFlow = false;
    this.accountService.getTempUserDetails(tempUserId).subscribe(
      data => {
        if (data.message == "Success") {
          this.accountFormGroup.controls.policyNumberFormControl.setValue(data.policyNumber);
          this.accountFormGroup.controls.emailFormControl.setValue(data.email);
          this.accountFormGroup.controls.zipFormControl.setValue(data.zipCode);
          this.user.appRoleId = data.roleId;
          this.user.roleName = data.roleName;
          // if (data.roleId == "1") {
          this.user.emailFor = `${ENV.createRequest.emailForNewAdmin}`;
          //}

          // If a user is invited, creates the account, then has the account deleted,
          // when we re-invite them, they will still be present in the b2c table
          // The following API call checks for this. If they are found in b2c, they must use the same userid
          // they used during the first account creation. If no record is found, continue as normal.
          let subscription1 = this.accountService.checkEmail(data?.email).subscribe(
            data => {
              if(data.objectID) {
                this.existingObjectID = data.objectID;
              }
              if (data.userName) {
                this.existingUserName = data.userName;
                this.reinvitedUserFlow = true;
                this.reviewFormGroup.get('userIdFormControl').setValue(data.userName);
                this.reviewFormGroup.get('userIdFormControl').disable({ onlySelf: true });
              }
              this.saveVerificationCode();
              this.currentStep = 2;
              this.apiInProgress = false;
            }, error => {
              this.systemError = true;
              this.apiInProgress = false;
              this.currentStep = 1;
            }
          );

        }
        else if (data.message == "User not found" && data.policyNumber == null) {
          this.apiInProgress = false;
          this.tempUserNotFound = true;
          this.currentStep = 1;
        }
        else {
          this.apiInProgress = false;
          this.systemError = true;
          this.currentStep = 1;
        }
      }, error => {
        this.systemError = true;
        this.apiInProgress = false;
        this.currentStep = 1;
      }
    );
  }

  ngOnInit() {
    this.titleService.setTitle(en.createAccount.setTitle);
    this.taggingService.view({
      step: 'account_information',
      journey_step_number: '1',
      journey: 'Sign Up'
    });

    this.loadLinksFromLocale();
    // this.scriptService.loadGlia();

    /*     if (sessionStorage.getItem('localefrmURL')) {
          this.router.navigate(['/create-account', sessionStorage.getItem('localefrmURL')])
        } */
  }

  checkiPhoneTips(inp: string) {
    if (window.navigator.platform === 'iPhone') {
      if (inp === 'on') {
        this.showiPhoneTips = true;
      } else if (inp === 'off') {
        this.showiPhoneTips = false;
      }
    } else {
      this.showiPhoneTips = false;
    }
  }

  formatPolicy() {
    this.autoAddDash();
    var policy = this.accountFormGroup.controls.policyNumberFormControl.value;
    var n = (policy.match(/-/g) || []).length;
    if (n > 1 && !policy.endsWith('-HK')) { //exclude HongKong policies which end with '-HK'
      var f = policy.indexOf('-', 1);
      var l = policy.indexOf('-', f + 1);
      var substr = policy.substring(0, l);
      this.accountFormGroup.controls.policyNumberFormControl.setValue(substr);
    }
  }

  //Handles paste event for verification code
  pasted() {
    if (this.field1.nativeElement.value.length > 1) {
      let digits = this.splitText();
      this.focusThis(digits)
    }
  }

  //Determines where the next focus should be for verification code
  focusThis(nextIndex: number, event?) {
    if (nextIndex == 2) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.field1.nativeElement.focus();
      }
      else {
        this.field2.nativeElement.focus();
      }
    } else if (nextIndex == 3) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('secondDigit').setValue(' ');
        this.field1.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('secondDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('secondDigit').setValue(event.key);
        }
        this.field3.nativeElement.focus();
      }

    } else if (nextIndex == 4) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('thirdDigit').setValue(' ');
        this.field2.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('thirdDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('thirdDigit').setValue(event.key);
        }
        this.field4.nativeElement.focus();
      }
    } else if (nextIndex == 5) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fourthDigit').setValue(' ');
        this.field3.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fourthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fourthDigit').setValue(event.key);
        }
        this.field5.nativeElement.focus();
      }
    } else if (nextIndex == 6) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fifthDigit').setValue(' ');
        this.field4.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fifthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fifthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
    else if (nextIndex >= 7) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('sixthDigit').setValue(' ');
        this.field5.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('sixthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('sixthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
  }

  //Splits up verification code if user tries to paste
  splitText() {
    var str = this.field1.nativeElement.value;
    var splitted = str.split("");
    this.confirmFormGroup.setValue({
      firstDigit: splitted[0],
      secondDigit: splitted[1] || '',
      thirdDigit: splitted[2] || '',
      fourthDigit: splitted[3] || '',
      fifthDigit: splitted[4] || '',
      sixthDigit: splitted[5] || ''
    })
    return splitted.length;
  }

  ngOnDestroy() {
    this.utilityService.clearSubscriptions(this.subscriptions);
    sessionStorage.removeItem('tempUserId');
  }

  onSubmit(event, value) {
    event.preventDefault();
  }

  resetStep(event) {
    this.utilityService.clearSubscriptions(this.subscriptions);
    this.systemError = false;
    this.errorReturned = false;
    this.codeErrorReturned = false;
    this.dataError = false;
    this.registerErrorReturned = false;
    this.multiPolicySaveHadAnError = false;
    this.apiInProgress = false;
    this.reviewFormGroup.reset();
    this.accountFormGroup.reset();
    this.confirmFormGroup.reset();
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep = 1;
    this.taggingService.view({
      step: 'account_information',
      journey_step_number: '1',
      journey: 'Sign Up'
    });
  }

  previousStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.errorReturned = false;
    this.codeErrorReturned = false;
    this.dataError = false;
    this.registerErrorReturned = false;
    this.currentStep--;
    this.setActiveStep();
  }

  nextStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.errorReturned = false;
    this.codeErrorReturned = false;
    this.registerErrorReturned = false;
    this.currentStep++;
    this.setActiveStep();
  }

  setStep(step) {
    window.scrollTo(0,0);
    this.errorReturned = false;
    this.codeErrorReturned = false;
    this.registerErrorReturned = false;
    this.currentStep = step;

    this.currentStepText = this.steps.find(x => x.order === step).label;
    this.setActiveStep();
  }

  private setActiveStep() {
    this.systemError = false;
    this.errorReturned = false;
    this.codeErrorReturned = false;
    this.registerErrorReturned = false;
    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );
  }

  //First page flow has begun (1)
  checkEmail() {
    if (!this.accountFormGroup.valid && !this.multiPolicyFormGroup.valid) {
      this.accountFormGroup.markAllAsTouched();
      this.multiPolicyFormGroup.markAllAsTouched();
      return;
    }
    //Clean slate
    this.arrayOfLogics = [];
    this.apiInProgress = true;
    this.existingObjectID = null;
    this.existingUserName = null;
    this.systemError = false;
    this.errorReturned = false;
    this.reinvitedUserFlow = false;

    this.taggingService.link({
      link_name: 'send_verification_email'
    });

    //Check if email is already registered in B2C
    this.accountService.checkEmail(this.showMultiPolicy ? this.mpEmail.value : this.regularEmail.value).subscribe(
      data => {
        if (data.userStatus == 2) { //policy exists
          if (this.showMultiPolicy) {
            this.mpEmail.setErrors({ 'inuse': true });
          } else {
            this.regularEmail.setErrors({ 'inuse': true });
          }
          this.apiInProgress = false;
        } else {
          //if email exists but not registered for a policy, continue
          this.existingObjectID = data.objectID;
          this.existingUserName = data.userName;
          if (data.userName && data.userName != '') {
            this.username.setValue(data.userName);
            this.username.disable({ onlySelf: true });
          } else {
            this.username.enable({ onlySelf: true });
          }

          //search policies now -
          this.policyValidate();
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
          this.errorReturned = false;
        } else {
          this.scriptError = false;
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
  }

  //(3)
  checkRoleUsersAndDigitalWallet() {
    if (!this.isUsaUser) {  //User roles don't matter, and digital wallet doesn't matter
      this.showAdminToggle = false;
      this.user.approved = true;  //We use this on the success page
      // this.allowedToEarnDWPoints = false;
      this.arrayOfLogics.forEach(function (x) {
        x.allowedToEarnDWPoints = false;
        x.approved = true;
        x.active = true;
      });
      this.saveVerificationCode();
      return;
    }

    let observableArray = [];
    //If it is a USA user, check the number of users registered already, and check for digital wallet
    let defaultPolicy = this.policy1.value ? this.policy1.value : this.policy.value;
    observableArray.push(this.accountService.checkRoleUsers(defaultPolicy));

    if (this.showMultiPolicy) {
      observableArray.push(this.accountService.checkRoleUsers(this.policy2.value));
      if (this.showPolicy3) {
        observableArray.push(this.accountService.checkRoleUsers(this.policy3.value));
      }
    }

    //Checks the number of users on a policy
    forkJoin(observableArray).subscribe(
      ([resp1, resp2, resp3]) => {
        let res1: any = resp1;
        let res2: any = resp2;
        let res3: any = resp3;
        let arrayOfResponses = [res1?.data, res2?.data, res3?.data];

        //For each policy, assign values based on if there are already users registered or not
        for (let i=0; i<arrayOfResponses.length; i++) {
          if (arrayOfResponses[i] && arrayOfResponses[i].numberOfUsers > 0) {
            if (!this.showMultiPolicy) {  //If not a multi policy registration, and there was more than 1 user already registered, show them the admin toggle
              this.showAdminToggle = true;
            }
            this.arrayOfLogics[i].allowedToEarnDWPoints = false;
            this.arrayOfLogics[i].approved = false;
            this.arrayOfLogics[i].active = true;
          } else if (arrayOfResponses[i]) {
            this.arrayOfLogics[i].allowedToEarnDWPoints = 'uncertain';
            this.arrayOfLogics[i].approved = true;
            this.arrayOfLogics[i].active = true;
          }
        }

        if (this.arrayOfLogics[0].approved) {
          this.user.approved = true;
        } else {
          this.user.approved = false;
        }

        //Prepare request for checking Digital Wallet
        let request = {
          productSourceSystem: "gw",
          sourceSystemIdentifierName: "PolicyNumber",
          sourceSystemIdentifier: '',
          country: "US",
          roleType: "string",
          productType: "string",
        }

        let dwObservables = [];

        //If there were any policies that we could not determine if they could earn DW points based on # of users registered, go here.
        for (let j=0; j<this.arrayOfLogics.length; j++) {
          if (this.arrayOfLogics[j].allowedToEarnDWPoints == 'uncertain') {
            request.sourceSystemIdentifier = this.arrayOfLogics[j].policy;
            dwObservables.push(this.billingService.DWgetSummary(request));
          }
        }

        //If we need to check the DW API because of uncertain policies - go here.
        if (dwObservables.length > 0) {
          forkJoin(dwObservables).subscribe(
            ([resp1, resp2, resp3]) => {
              let res1: any = resp1;
              let res2: any = resp2;
              let res3: any = resp3;
              let arrayOfResponses = [res1?.data, res2?.data, res3?.data];

              for (let k=0; k<arrayOfResponses.length; k++) {
                if (arrayOfResponses[k] && arrayOfResponses[k].wallet?.walletID && arrayOfResponses[k].wallet?.rewardEligible == 'True') {
                  if (this.arrayOfLogics[k]) {
                    this.arrayOfLogics[k].allowedToEarnDWPoints = true; //They are the first account to be created under a specific policy number, and have a wallet + rewards eligible so display the earn points message at the end.
                  }
                } else {
                  if (this.arrayOfLogics[k]) {
                    this.arrayOfLogics[k].allowedToEarnDWPoints = false;  //They are the first account to be created under a specific policy number but don't have a wallet/aren't eligible for rewards so don't display the message.
                  }
                }
              }

              this.saveVerificationCode();
            }
          ), error => {
            this.arrayOfLogics.forEach(function (x) {
              if (x.allowedToEarnDWPoints == 'uncertain') {
                x.allowedToEarnDWPoints = false;  //Default to the not eligible message when this API errors out (IB/TrueCore handles the logic for actually giving the user points)
              }
            });
            this.saveVerificationCode();
          }
        } else {
          this.saveVerificationCode();
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
        } else {
          this.scriptError = false;
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
  }

  // create login this gets called
  checkUserID() {
    this.systemError = false; //Refresh the error statuses each time this function is called.
    this.scriptError = false;
    this.dataError = false;

    this.apiInProgress = true;
    this.taggingService.link({
      link_name: 'sign_up'
    });

    //If a user already has an objectID and userName, they may be fully set up in B2C, but not in our UserInfo table.
    //SetupSubmitUser will take care of whether or not to create new / update.
    if (this.existingObjectID && this.existingUserName && this.existingUserName != '') {
      this.setupSubmitUser();
    } else {
      // For re-invited users, we do not need to verify their username (it has already been pre-populated for them)
      if (this.reinvitedUserFlow) {
        this.username.setErrors(null);
        this.setupSubmitUser();
      } else {
        //If a user does not have both an objectID and username in B2C, they are most likely NOT setup in B2C.
        //So, go through normal process and check if username is already taken in B2C by someone else.
        let subscription2 = this.accountService.checkUserID(this.username.value).subscribe(
          data => {
            if (data.objectId) {
              this.apiInProgress = false;
              this.username.setErrors({ 'inuse': true });
            } else {
              //Username is free to take, continue
              this.username.setErrors(null);
              this.setupSubmitUser();
            }
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.scriptError = true;
              this.systemError = false;
              this.apiInProgress = false;
            } else {
              this.scriptError = false;
              this.systemError = true;
              this.apiInProgress = false;
            }
          }
        );
        this.subscriptions.push(subscription2);
      }
    }
  }

  //(4)
  saveVerificationCode() {
    this.createVerificationInterface.data.emailAddress = this.mpEmail.value ? this.mpEmail.value : this.regularEmail.value;
    let subscription3 = this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        if ((data.responseMessage).toLowerCase() === 'new verification code created' ||
          (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.setStep(2);
          this.apiInProgress = false;

          this.taggingService.view({
            step: 'enter_unique_code',
            journey_step_number: '2',
            journey: 'Sign Up'
          });
          this.taggingService.link({
            event_flag: 'send_verification_email'
          });
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
        }
        else {
          this.scriptError = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
    this.subscriptions.push(subscription3);
  }

  //Send an email to user-entered email with a verification code
  sendVerificationCode(event) {
    this.user.emailAddress = this.showMultiPolicy ? this.mpEmail.value : this.regularEmail.value;
    this.emailModel.data.email = this.user.emailAddress;
    this.emailModel.emailFor = "CreateAccount";
    let subscription5 = this.accountService.sendVerificationCodeEmail(this.emailModel).subscribe(
      data => {
        this.setStep(2);
        this.apiInProgress = false;
        this.taggingService.view({
          step: 'enter_unique_code',
          journey_step_number: '2',
          journey: 'Sign Up'
        });
        this.taggingService.link({
          event_flag: 'send_verification_email'
        });
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
        }
        else {
          this.scriptError = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
    this.subscriptions.push(subscription5);
  }

  //Check verification code that user enters with the code that was sent
  checkVerificationCode() {
    this.taggingService.link({
      link_name: 'enter_unique_code'
    });
    let userInput = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    //Use the retrieveVerificationCode function here to compare userInput
    this.verifyCodeInterface.emailId = this.showMultiPolicy ? this.mpEmail.value : this.regularEmail.value;
    this.verifyCodeInterface.toVerify = userInput;

    let subscription6 = this.accountService.verifyCode(this.verifyCodeInterface).subscribe(
      data => {
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.setStep(3);
          this.taggingService.link({
            event_flag: 'enter_unique_code'
          });
          this.taggingService.view({
            step: 'create_account',
            journey_step_number: '3',
            journey: 'Sign Up'
          });
        } else {
          this.codeErrorReturned = true;
        }
      }, error => {
        //get verification code error
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
        }
        else {
          this.scriptError = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
    this.subscriptions.push(subscription6);
  }

  //Creates a User!
  setupSubmitUser() {
    this.dataError = false;
    this.user.policyNumber = this.showMultiPolicy ? this.policy1.value.trim() : this.policy.value.trim();
    this.user.emailAddress = this.showMultiPolicy ? this.mpEmail.value : this.regularEmail.value;
    this.user.zipCode = this.showMultiPolicy ? this.zip1.value.trim() : this.zip.value ? this.zip.value.trim() : "none";
    this.user.firstName = this.fName.value;
    this.user.lastName = this.lName.value;
    this.user.userName = this.username.value;
    this.user.password = this.password.value;

    if (!this.isTempUser) {
      if (this.isUsaUser) {
        //this.user.userRole = this.isChecked ? "1" : "0"; //if admin selected, send value of 1
        this.user.userRole = "1";
        if (this.isChecked) {
          this.user.appRoleId = "1";
          this.user.emailFor = `${ENV.createRequest.emailForNewAdmin}`;
          this.user.roleName = `${ENV.rolename.approleName}`;
        } else if (this.showAdminToggle == false) {  //They are not a temp user, and they are the first user to register for this policy. or they are a multipolicy account
          this.user.appRoleId = "1";
        } else {
          this.user.appRoleId = "1000";
        }
      } else {
        this.user.userRole = "1"; //if the user is not from USA ?, by default we are making them Admin.
        this.user.appRoleId = "1";
        this.user.approved = true;
      }
    } else if (this.isTempUser) {
      this.user.userRole = "1";
      this.user.approved = true;
    }

    let request = {
      userObjectId: "",
      policyNumber: this.user.policyNumber,
      emailAddress: this.user.emailAddress,
      zipCode: this.user.zipCode,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      userName: this.user.userName,
      password: this.user.password,
      applicationName: `${ENV.createRequest.applicationName}`,
      emailFor: this.user.emailFor,
      environment: `${ENV.createRequest.environment}`,
      countryLocale: sessionStorage.getItem('locale'),
      authorizeUserLink: `${ENV.baseUrl.web}login`,
      userRole: this.user.userRole,
      appRoleId: this.user.appRoleId,
      approved: this.isTempUser ? this.user.approved : this.arrayOfLogics[0].approved,
      appRoleName: this.user.roleName,
    }

    if (this.existingObjectID) {  //email has a record in B2C
      request = { ...request, userObjectId: this.existingObjectID };
      this.user.userObjectId = this.existingObjectID;
      if (this.existingUserName && this.existingUserName != '') { //If there is an existing username, just update(create) the user info regularly(?) in our Table
        this.updateUser(request);
      } else {  //If there is NO existing username, we have a separate API that sets the username inside of B2C, then we can update(create) the user as normal in our Table
        this.accountService.updateUserName(this.user).subscribe(
          data => {
            if (data) {
              this.updateUser(request);
            } else {
              this.handleError(data);
            }
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.scriptError = true;
              this.apiInProgress = false;
            } else {
              this.scriptError = false;
              this.handleError(error);
            }
          }
        );
      }
    } else {  //Email has no record in B2C - do a regular create user
      this.submitUser(request);
    }
  }

  submitUser(request) {
    this.accountService.register(request).subscribe(
      data => {
        if (data && data.objectId) {
          if (this.showMultiPolicy) {
            this.addMultiPolicies(data);
          } else {
            this.sendSuccessEmail(data);
          }
        } else {
          this.apiInProgress = false;
          this.dataError = true;
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        } else {
          this.scriptError = false;
          this.handleError(error);
        }
      }
    );
    this.taggingService.link({
      completion_event: '5026'
    });
  }

  //The updateUser response is very different from create-user response.
  //UpdateUser API response only has - "userID" and "responseMessage"
  //userID is used for Terms, responseMessage will be "true" or "false" STRING
  updateUser(request) {
    this.accountService.update(request).subscribe(
      data => {
        if (data?.responseMessage && data?.responseMessage == 'true') {
          if (this.showMultiPolicy) {
            //They are not done yet - try to add multipolicies
            this.addMultiPolicies(data);
          } else {
            //done. send email.
            this.sendSuccessEmail(data);
          }
        } else {
          this.apiInProgress = false;
          this.dataError = true;
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.apiInProgress = false;
        } else {
          this.scriptError = false;
          this.handleError(error);
        }
      }
    );
    this.taggingService.link({
      completion_event: '5026'
    });
  }

  addMultiPolicies(data) {
    let arrayOfLogicsNoUndefined = this.arrayOfLogics.filter(x => x != undefined);

    let request = {
      userID: data.userID,
      policyNumber: '',
      policyZip: '',
      approve: null,
      active: null,
      appRoleId: '1',
      claimNotifications: '0',
      shipperNumber: '',  //will never be anything here
      nickName: ''  //never be anything here
    };

    let observableArray = [];
    arrayOfLogicsNoUndefined.shift();  //Remove the first item, because that was the default policy already registered.
    for (let i=0; i<arrayOfLogicsNoUndefined.length; i++) {
      request.policyNumber = arrayOfLogicsNoUndefined[i].policy;
      request.approve = arrayOfLogicsNoUndefined[i].approved ? 1 : 0;
      request.active = arrayOfLogicsNoUndefined[i].active ? 1 : 0;
      observableArray.push(this.policyService.addNewPolicy(request));
    }

    forkJoin(observableArray).subscribe(
      responses => {
        this.sendSuccessEmail(data);
      }, error => {
        this.multiPolicySaveHadAnError = true;
        this.sendSuccessEmail(data);
      }
    );

  }

  sendSuccessEmail(registrationResponse) {
    //No matter if it is a multipolicy registration or a singular policy registration. this.user object will always have the default policy holder info
    this.emailModel.emailFor = "RegistrationComplete";
    this.emailModel.data.email = this.user.emailAddress;
    this.emailModel.data.userID = this.user.userName;
    this.emailModel.data.fName = this.user.firstName;
    this.emailModel.data.lName = this.user.lastName;

    this.accountService.sendCompletedRegistrationEmail(this.emailModel).subscribe(
      data => {
        this.apiInProgress = false;
        if (data) {
          this.dataError = false;
        } else {
          this.dataError = true;
        }
      }, error => {
        this.apiInProgress = false;
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        }
        else {
          this.scriptError = false;
          this.handleError(error);
        }
      }
    );
    this.acceptTerms(registrationResponse);
  }

  acceptTerms(registrationResponse) {
    this.termsRequest.userId = registrationResponse.userID;
    let localefromURL = sessionStorage.getItem('localefrmURL');
    if (localefromURL) {
      if (localefromURL.endsWith('-CA') || localefromURL.startsWith('CA-')) {
        this.termsRequest.termId = SiteConfig.terms.ca.TermID
      }
      else if (localefromURL.endsWith('-GB') || localefromURL.startsWith('GB-')) {
        this.termsRequest.termId = SiteConfig.terms.gb.TermID
      }
      else if (localefromURL.endsWith('-DE') || localefromURL.startsWith('DE-')) {
        this.termsRequest.termId = SiteConfig.terms.de.TermID
      }
      else if (localefromURL.endsWith('-FR') || localefromURL.startsWith('FR-')) {
        this.termsRequest.termId = SiteConfig.terms.fr.TermID
      }
      else if (localefromURL.endsWith('-IT') || localefromURL.startsWith('IT-')) {
        this.termsRequest.termId = SiteConfig.terms.it.TermID
      }
    }
    this.accountService.acceptTerms(this.termsRequest).subscribe(
      data => {
        if (data) {
          this.dataError = false;
          this.setStep(4);
          this.taggingService.view({
            step: 'signup_success',
            journey_step_number: '4',
            journey: 'Sign Up'
          });
        } else {
          this.dataError = true;
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        } else {
          this.scriptError = false;
          this.setStep(4);
          this.taggingService.view({
            step: 'signup_success',
            journey_step_number: '4',
            journey: 'Sign Up'
          });
          this.handleError(error);
        }
      }
    );
    this.saveUser.userId = registrationResponse.objectID;
  }

  handleError(err) {
    this.apiInProgress = false;
    this.systemError = true;
    this.logInterface.routeName = this.router.url;
    this.logInterface.exceptionMessage = err.toString();
    this.utilityService.uiLog(this.logInterface).subscribe(
      data => { }, error => { }
    );
  }

  autoAddDash() {
    let policyNumber = this.accountFormGroup.controls.policyNumberFormControl.value;
    if (policyNumber.startsWith('15914024') && !policyNumber.startsWith('15914024-')) {
      this.accountFormGroup.controls.policyNumberFormControl.setValue(policyNumber.replace('15914024', '15914024-'));
    }
  }

  tagging() {
    this.taggingService.link({
      link_name: 'login'
    });
  }

  goToLoginPage() {
    this.router.navigate(['/login']);
  }

  goToWallet() {
    this.systemError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
        } else {
          this.systemError = true;
        }
      }, error => {
        this.systemError = true;
      }
    );
  }

  determineLocale() {
    if (this.isUsaUser) {
      this.steps = [
        { order: 1, label: en.createAccount.componentData.stepLabel1, isActive: true },
        { order: 2, label: en.createAccount.componentData.stepLabel2, isActive: false },
        { order: 3, label: en.createAccount.componentData.stepLabel3, isActive: false }
      ];
      this.currentStepText = en.createAccount.componentData.stepLabel1;
    } else if (this.isUkUser) {
      this.steps = [
        { order: 1, label: enGb.createAccount.componentData.stepLabel1, isActive: true },
        { order: 2, label: enGb.createAccount.componentData.stepLabel2, isActive: false },
        { order: 3, label: enGb.createAccount.componentData.stepLabel3, isActive: false }
      ];
      this.currentStepText = enGb.createAccount.componentData.stepLabel1;
    } else if (this.isCanadaUser) {
      this.steps = [
        { order: 1, label: enCa.createAccount.componentData.stepLabel1, isActive: true },
        { order: 2, label: enCa.createAccount.componentData.stepLabel2, isActive: false },
        { order: 3, label: enCa.createAccount.componentData.stepLabel3, isActive: false }
      ];
      this.currentStepText = enCa.createAccount.componentData.stepLabel1;
    } else if (this.isFranceUser) {
      let locale = sessionStorage.getItem('locale');
      if (locale == 'en-FR') {
        this.steps = [
          { order: 1, label: enFr.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: enFr.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: enFr.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = enFr.createAccount.componentData.stepLabel1;
      } else if (locale == 'fr-FR') {
        this.steps = [
          { order: 1, label: frFr.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: frFr.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: frFr.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = frFr.createAccount.componentData.stepLabel1;
      }
    } else if (this.isGermanyUser) {
      let locale = sessionStorage.getItem('locale');
      if (locale == 'en-DE') {
        this.steps = [
          { order: 1, label: enDe.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: enDe.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: enDe.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = enDe.createAccount.componentData.stepLabel1;
      } else if (locale == 'de-DE') {
        this.steps = [
          { order: 1, label: deDe.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: deDe.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: deDe.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = deDe.createAccount.componentData.stepLabel1;
      }
    } else if (this.isItalyUser) {
      let locale = sessionStorage.getItem('locale');
      if (locale == 'en-IT') {
        this.steps = [
          { order: 1, label: enIt.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: enIt.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: enIt.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = enIt.createAccount.componentData.stepLabel1;
      } else if (locale == 'it-IT') {
        this.steps = [
          { order: 1, label: itIt.createAccount.componentData.stepLabel1, isActive: true },
          { order: 2, label: itIt.createAccount.componentData.stepLabel2, isActive: false },
          { order: 3, label: itIt.createAccount.componentData.stepLabel3, isActive: false }
        ];

        this.currentStepText = itIt.createAccount.componentData.stepLabel1;
      }
    }
  }

  loadLinksFromLocale() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-DE') {
      this.deprivacyNotice = ENV.links.deprivacyNotice;
    }
    else if (locale == 'de-DE') {
      this.deprivacyNotice = ENV.links.deDeprivacyNotice;
    }
    else if (locale == 'en-FR') {
      this.frprivacyNotice = ENV.links.frprivacyNotice;
    }
    else if (locale == 'fr-FR') {
      this.frprivacyNotice = ENV.links.frFrprivacyNotice;
    }
    else if (locale == 'en-IT') {
      this.itprivacyNotice = ENV.links.itprivacyNotice;
    }
    else if (locale == 'it-IT') {
      this.itprivacyNotice = ENV.links.itItprivacyNotice;
    }
  }

  togglePolicy3() {
    this.showAddPolicy3 = !this.showAddPolicy3;
    this.showPolicy3 = !this.showPolicy3;

    if (this.showPolicy3) {
      this.policy3.setValidators([Validators.required, Validators.minLength(6)]);
      this.zip3.setValidators([Validators.required, Validators.pattern(ALPHA_NUMERIC_AND_SPACES_PATTERN)]);
    } else {
      this.policy3.setValidators(null);
      this.zip3.setValidators(null);
    }
  }

  //Validates one or multiple policies (2)
  policyValidate() {
    let responseArray = [];

    //send policy zip combos into API requests, of which the observables are pushed into an array
    if (this.showMultiPolicy) {
      responseArray.push(this.accountService.policyCheck(this.policy1.value.trim(), this.zip1.value.trim()));
      this.arrayOfLogics.push({ policy: this.policy1.value });
      responseArray.push(this.accountService.policyCheck(this.policy2.value.trim(), this.zip2.value.trim()));
      this.arrayOfLogics.push({ policy: this.policy2.value });
      if (this.showPolicy3) {
        responseArray.push(this.accountService.policyCheck(this.policy3.value.trim(), this.zip3.value.trim()));
        this.arrayOfLogics.push({ policy: this.policy3.value });
      }
    } else {  //singular policy
      responseArray.push(this.accountService.policyCheck(this.policy.value.trim(), this.zip.value.trim()))
      this.arrayOfLogics.push({ policy: this.policy.value.trim() });
    }

    //forkjoin the array of observables and subscribe to the result, it will only notify once all API requests finish
    forkJoin(responseArray).subscribe(
      data => {
        let trues = data.filter(x => x == true).length; //The count of "true"
        if ((this.showMultiPolicy && this.showPolicy3 && trues == 3) || (this.showMultiPolicy && !this.showPolicy3 && trues == 2) || (!this.showMultiPolicy && trues == 1)) { //pass
          //Check user roles and digital wallets
          this.checkRoleUsersAndDigitalWallet();
          this.scriptError = false;
          this.systemError = false;
        } else {  //fail
          this.apiInProgress = false;
          this.errorReturned = true;
        }
      }, error => {
        this.apiInProgress = false;
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
        } else {
          this.systemError = true;
        }
      }
    );
  }

  get policy() { return this.accountFormGroup.controls.policyNumberFormControl }
  get policy1() { return this.multiPolicyFormGroup.controls.policy1; }
  get policy2() { return this.multiPolicyFormGroup.controls.policy2; }
  get policy3() { return this.multiPolicyFormGroup.controls.policy3; }
  get zip() { return this.accountFormGroup.controls.zipFormControl; }
  get zip1() { return this.multiPolicyFormGroup.controls.zip1; }
  get zip2() { return this.multiPolicyFormGroup.controls.zip2; }
  get zip3() { return this.multiPolicyFormGroup.controls.zip3; }
  get regularEmail() { return this.accountFormGroup.controls.emailFormControl; }
  get mpEmail() { return this.multiPolicyFormGroup.controls.email; }
  get username() { return this.reviewFormGroup.controls.userIdFormControl; }
  get fName() { return this.reviewFormGroup.controls.firstNameFormControl; }
  get lName() { return this.reviewFormGroup.controls.lastNameFormControl; }
  get password() { return this.reviewFormGroup.controls.passwordFormControl; }
}
