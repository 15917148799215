import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { DeniedPartyRequestData, DeniedPartyResponse, DeniedPartyResponseV2, PartyInfo } from './../shared/models/denied-party.interface';
import { retry, map, concatMap, catchError, tap } from 'rxjs/operators';
import { ApiEndpoints as API } from './../../config/api-endpoints';
import { environment as ENV } from './../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { TranslateService } from '@ngx-translate/core';
import { Permissions } from '../shared/models/permissions';
import { UserPermission } from '../shared/models/user-permission';
import { ScriptService } from './script.service';



enum USER_ROLES {
  ADMINISTRATOR = "1",
  ADJUSTER = "2",
  GUESTUSER = "3",
  HPGUESTUSRER = "4",
  SHOPIFYUSER = "5",
  TUPSSUSER = "6",
  DDUSER = "7"
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo: any;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private locale = new BehaviorSubject<string>('en');
  private _isHVCMFlow: boolean;

  private permissions: Permissions[] = [];
  private userPermissions: UserPermission = new UserPermission();

  constructor(
    private loginService: LoginService,
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private scriptService: ScriptService
  ) {

  }

  public setUserPermissions(permission: any) {
    this.permissions.splice(0, this.permissions.length);
    permission.map((value) => {
      if (!this.permissions.find(x => x.permissionName === value)) {
        var permission = new Permissions();
        permission.permissionName = value;
        this.permissions.push(permission);
      }
    });
    sessionStorage.setItem('permissions', JSON.stringify(this.permissions));
  }


  public getUserPermissions(): UserPermission {
    this.permissions = JSON.parse(sessionStorage.getItem('permissions'));

    if (!this.permissions) {  //Zero permissions - user most likely not logged in yet, or something very wrong with their account
      return null;
    }

    if ((this.permissions?.length > 0) && (this.isUsaUser)) {
      this.userPermissions.isAnalytics = this.permissions.find(x => x.permissionName === 'Analytics') ? true : false;
      this.userPermissions.isBillingView = this.permissions.find(x => x.permissionName === 'BillingView') ? true : false;
      this.userPermissions.isBillingPayments = this.permissions.find(x => x.permissionName === 'BillingPayments') ? true : false;
      this.userPermissions.isPolicyView = this.permissions.find(x => x.permissionName === 'PolicyView') ? true : false;
      this.userPermissions.isPolicyUpdate = this.permissions.find(x => x.permissionName === 'PolicyUpdate') ? true : false;
      this.userPermissions.isClaimView = this.permissions.find(x => x.permissionName === 'ClaimView') ? true : false;
      this.userPermissions.isFileClaim = this.permissions.find(x => x.permissionName === 'ClaimFile') ? true : false;
      this.userPermissions.isClaimPayee = this.permissions.find(x => x.permissionName === 'ClaimPayee') ? true : false;
      this.userPermissions.isHVCM = this.permissions.find(x => x.permissionName === 'HVCM') ? true : false;
      this.userPermissions.isManagePaymentMethods = this.permissions.find(x => x.permissionName === 'ManagePaymentMethods') ? true : false;
      this.userPermissions.isSmartUpload = this.permissions.find(x => x.permissionName === 'SmartUpload') ? true : false;
      this.userPermissions.isUserManagement = this.permissions.find(x => x.permissionName === 'UserManagement') ? true : false;
    } else {
      this.userPermissions.isAnalytics = this.permissions.find(x => x.permissionName === 'Analytics') ? true : false;
      this.userPermissions.isBillingView = this.permissions.find(x => x.permissionName === 'BillingView') ? true : false;
      this.userPermissions.isBillingPayments = this.permissions.find(x => x.permissionName === 'BillingPayments') ? true : false;
      this.userPermissions.isPolicyView = this.permissions.find(x => x.permissionName === 'PolicyView') ? true : false;
      this.userPermissions.isPolicyUpdate = this.permissions.find(x => x.permissionName === 'PolicyUpdate') ? true : false;
      this.userPermissions.isClaimView = this.permissions.find(x => x.permissionName === 'ClaimView') ? true : false;
      this.userPermissions.isFileClaim = this.permissions.find(x => x.permissionName === 'ClaimFile') ? true : false;
      this.userPermissions.isClaimPayee = this.permissions.find(x => x.permissionName === 'ClaimPayee') ? true : false;
      this.userPermissions.isHVCM = this.permissions.find(x => x.permissionName === 'HVCM') ? true : false;
      this.userPermissions.isManagePaymentMethods = this.permissions.find(x => x.permissionName === 'ManagePaymentMethods') ? true : false;
      this.userPermissions.isSmartUpload = this.permissions.find(x => x.permissionName === 'SmartUpload') ? true : false;
      this.userPermissions.isUserManagement = this.permissions.find(x => x.permissionName === 'UserManagement') ? false : false;
    }
    return this.userPermissions;
  }

  getUserInfo() {
    if (!this.userInfo) {
      this.userInfo = JSON.parse(sessionStorage.getItem('userDetails'));
    }
    return this.userInfo;
  }

  setUserInfo(userInfo: any) {
    sessionStorage.setItem('userDetails', JSON.stringify(userInfo));
    this.userInfo = userInfo;
  }

  updateUserInfo(userInfo: any) {
    let userDetails = {
      ...JSON.parse(sessionStorage.getItem('userDetails')),
      ...userInfo
    }
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    this.userInfo = userDetails;
  }

  isParentPolicy() {
    let policyinfo = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyinfo && policyinfo.isParentPolicy) {
      return true;
    } else {
      return false;
    }
  }

  isEAUserorNonEA() { // is Enterprise Affiliate User or Non Enterprise Affiliate
    let policyinfo = JSON.parse(sessionStorage.getItem('policyDetails'));
    if ((policyinfo) && (policyinfo.isParentPolicy || policyinfo.isChildPolicy)) {
      return true;
    } else {
      return false;
    }
  }


  isGuestUser() {
    let isValidGuestUser = JSON.parse(sessionStorage.getItem('isValidGuestUser'));
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.GUESTUSER;
    }
    else if (isValidGuestUser != 'T') {
      return false;
    }
    else {
      return true
    }
  }

  isHPGuestUser() {
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.HPGUESTUSRER;
    }
    else {
      return true
    }
  }

  isGuestClaim() {
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.GUESTUSER || this.getUserInfo().userRoleID === USER_ROLES.HPGUESTUSRER;
    }
    else {
      return true
    }
  }

  isShopifyUser() {
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.SHOPIFYUSER;
    }
    else {
      return false
    }
  }

  isDeliveryDefenseUser() {
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.DDUSER;
    }
    else {
      return false
    }
  }

  isDDBillingFlow() {
    return sessionStorage.getItem('DDFlow') == 'billing';
  }

  isDDClaimsFlow() {
    return sessionStorage.getItem('DDFlow') == 'claims';
  }

  isTupssUser() {
    return this.getUserInfo().userRoleID === USER_ROLES.TUPSSUSER;
  }

  isUserAdjuster() {
    if (this.userInfo) {
      return this.getUserInfo().userRoleID === USER_ROLES.ADJUSTER;
    }
    else {
      return true
    }
  }

  isAdminRole() {
    return this.getUserInfo().userRoleID === USER_ROLES.ADMINISTRATOR;
  }

  // For now, we can base it off if they have access to billing content. Ideally, only admin and account managers can see DW material.
  canAccessDigitalWallet() {
    return this.getUserPermissions().isBillingPayments;
  }

  getshopifyUserObjectUID() {
    if (this.isShopifyUser()) {
      return this.getUserInfo().objectUID;
    }
    else {
      return null
    }
  }
  isCanadaUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-CA') {
      return true;
    } else {
      return false;
    }
  }
  isUsaUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en') {
      return true;
    } else {
      return false;
    }
  }
  isUkUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-GB') {
      return true;
    } else {
      return false;
    }
  }
  isGermanyUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-DE' || locale == 'de-DE') {
      return true;
    } else {
      return false;
    }
  }
  isFranceUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-FR' || locale == 'fr-FR') {
      return true;
    } else {
      return false;
    }
  }
  isItalyUser() {
    let locale = sessionStorage.getItem('locale');
    if (locale == 'en-IT' || locale == 'it-IT') {
      return true;
    } else {
      return false;
    }
  }

  isInternalUser() {
    return this.getUserInfo().internalUser == true && this.getUserInfo().userRoleID != "2"; //excludes adjusters
  }

  isWalletUser() {
    let dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    return dwDetails?.walletID ? true : false;
  }

  isWalletTCsAccepted() {
    let dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    return dwDetails?.tcAcceptance == '1' ? true : false;
  }

  isWalletRewardEligible() {
    let dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    return dwDetails?.rewardEligible?.toLowerCase() == 'true' ? true : false;
  }

  isWalletCashEligible() {
    let dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    return dwDetails?.cashEligible?.toLowerCase() == 'true' ? true : false;
  }

  isUSPSUser() { // Tupss flex USPS policies check
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if(policyDetails?.policyType?.key == 'Flex' && policyDetails?.policyType?.value == 'Flexible Parcel Insurance' && policyDetails?.policySource == 'ISHIPFLEXMC') {
      return true;
    } else { return false; }
  }

  getCheckFeeAmount() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    return policyDetails?.checkProcessingFee && Number(policyDetails?.checkProcessingFee) ? Number(policyDetails.checkProcessingFee) : 0;
  }

  isIS4UAcquisitionUser() {
    let conversionInfo = JSON.parse(sessionStorage.getItem('is4upsQuoteDetails'));
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    // User must have a quote for IS4UPS conversion in sessionStorage, and they must not have TC coverage already

    if (conversionInfo && conversionInfo?.newRate && !policyDetails?.hasTCCoverage && this.getUserPermissions()?.isPolicyUpdate) {
      return true;
    } else {
      return false;
    }
  }

  deniedPartyCheck(partyInfo: PartyInfo) {
    let request = {
      'Party': {
        'ScreenType': 'Party',
        'CompanyName': partyInfo.company,
        /*'Address': {
            'AddressLine': partyInfo.address,
            'City': partyInfo.city,
            'State': partyInfo.state,
            'Zip': partyInfo.zip
        } */
        'Address': {
          'Country': 'United States of America'
        }
      }
    }
    return this.http.post<DeniedPartyResponse>(`${ENV.baseUrl.api}${API.CHECK_DENIED_PARTY}`, request, this.options).pipe(
      retry(3),
      map(result => {
        if (result.deniedPartyScreenerResponse.response.deniedPartySearchStatus === 'Denied Parties Found') {
          return true;
        }
        else {
          return false;
        }
      })
    );
  }

  deniedPartyCheckV2(data: DeniedPartyRequestData) {
    let request = {
      'party': {
        'address': {
          'city': data.city,
          'countryCode': data.country,
          'postalCode': data.zip,
          'state': data.state,
          'street': data.address
        },
        'companyName': data.company
      }
    }

    return this.http.post<DeniedPartyResponseV2>(`${ENV.baseUrl.api}${API.CHECK_DENIED_PARTY_V2}`, request, this.options).pipe(
      retry(3),
      map(result => {
        if (result && result.result == 'YES') {   //Denied party found
          return true;
        } else {  //No denied party found
          return false;
        }
      })
    );
  }

  policyCenterSearch(policy: string): Observable<any> {
    let shopifyUID = this.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.get<any>(`${ENV.baseUrl.api}${API.POLICY_CENTER_SEARCH}/${policy}` + "?" + "objectUID=" + shopifyUID);
    }
    else {
      return this.http.get<any>(`${ENV.baseUrl.api}${API.POLICY_CENTER_SEARCH}/${policy}`);
    }
  }

  dataBricksPolicySearch(policy: string): Observable<any> {
    return this.http.get<any>(`${ENV.baseUrl.api}${API.DataBricks_POLICY_SEARCH}` + "?" + "policyNumber=" + policy);
  }

  isValidGuestUser(guestUserToken: string) {
    return this.loginService.getGuestAccessToken(guestUserToken).pipe(
      tap(data => {
        if (data.isValid == 'T') {
          sessionStorage.setItem('guestData', JSON.stringify(data));
          sessionStorage.setItem('isValidGuestUser', JSON.stringify(data.isValid));
          return true;
        }
        else {
          return false;
        }
      }),
      catchError(err => {
        throw err;
      })
    )
  }

  guestUserLogin() {
    let guestUserInfo = JSON.parse(sessionStorage.getItem('guestData'));
    if (guestUserInfo.isValid != 'T' || !guestUserInfo.userID || !guestUserInfo.password) {
      throw new Error('Invalid');
    }
    sessionStorage.setItem('userid', atob(guestUserInfo.userID));
    return this.loginService.userLogin({
      username: atob(guestUserInfo.userID),
      password: atob(guestUserInfo.password)
    }).pipe(tap(token => {
      sessionStorage.setItem('access_token', token.accessToken);
      sessionStorage.setItem('refresh_token', token.refreshToken);
      sessionStorage.removeItem('guestData');
    }
    ))
  }
  getTokensForGuestUser(guestUserToken: string) {
    return this.loginService.getGuestAccessToken(guestUserToken).pipe(
      tap(data => {
        if (data.isValid != 'T' || !data.userID || !data.password) {
          throw new Error('Invalid');
        }
        sessionStorage.setItem('userid', atob(data.userID));
      }),
      concatMap((data) => this.loginService.userLogin({
        username: atob(data.userID),
        password: atob(data.password)
      }).pipe(
        tap((token => {
          sessionStorage.setItem('access_token', token.accessToken);
          sessionStorage.setItem('refresh_token', token.refreshToken);
        }))
      )),
      catchError(err => {
        throw err;
      })
    );
  }

  loginForGuestUser(landingUrl: string, updateWith?: any) {
    let errorUrl = 'login'
    this.loginService.userDetails(sessionStorage.getItem('access_token')).subscribe(
      data => {
        //Check if the user has access to the application by seeing if a policy number is passed in
        //or the token has a policy number attached to it
        if (updateWith || data && data.policyNumber) {
          //Policy num found, allow user in as normal

          //saves user permissions for OnDemand  AND guest users
          this.setUserPermissions(data.permissions);

          this.getPolicySearch(updateWith ? updateWith.policyNumber : data.policyNumber).subscribe(
            policydata => {
              if (policydata?.data && policydata?.message?.toLowerCase() == 'succeeded') {
                if (updateWith) {
                  data = { ...data, ...updateWith }
                  data.policyHoldersName = policydata.data.insured;
                  data.policyState = policydata.data.state;
                }
                sessionStorage.setItem('policyDetails', JSON.stringify(policydata.data));
                sessionStorage.setItem('userDetails', JSON.stringify(data));
              } else {
                this.router.navigateByUrl(errorUrl);
              }
              this.scriptService.pendoInit();
              this.router.navigateByUrl(landingUrl);
            },
            error => {
              this.router.navigateByUrl(errorUrl);
            }
          );
        }
        else {
          this.router.navigateByUrl(errorUrl);
        }
      },
      error => {
        this.router.navigateByUrl(errorUrl);
      }
    );
  }
  getAutoAuthProfile(UID: string) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.AUTO_AUTH_PROFILE}`, { UID: UID });
  }

  //Should theoretically only be called once, and then stored in sessionStorage.getItem('policyDetails')
  public getPolicySearch(policyNumber: string) {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getPolicyDetails}`;
    let shopifyUID = this.getshopifyUserObjectUID();
    return this.http.get<any>(endpoint + `?policyNumber=${policyNumber}` + (shopifyUID !== null ? `&objectUID=${shopifyUID}` : ``),
      this.httpOptions).pipe(
        tap(data => data ? this.setLocale(data?.data?.policyCountry_Ext) : null)
      );
  }

  public getPermissions(appRoleId: number) {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getPermissions}`;
    return this.http.get<any>(`${environment.baseUrl.api}${ApiEndpoints.getPermissions}?appRoleId=${appRoleId}`);
  }

  hasHVCM() {
    return JSON.parse(sessionStorage.getItem('policyDetails'))?.hvcm == true;
  }

  get isHVCMFlow() {
    return this._isHVCMFlow
  }

  set isHVCMFlow(hvcm) {
    this._isHVCMFlow = hvcm;
  }

  getHVCMUrl(request) {
    return this.http.post<{ data: { url: string } }>(`${ENV.baseUrl.api}${API.HVCM}`, request);
  }

  openHVCM(payload: any) {
    this.getHVCMUrl(payload).subscribe(
      res => {
        if (res?.data.url != '') {
          window.open(res.data.url);
        }
        else {
          window.open(ENV.hvcm.url);
        }
      },
      error => window.open(ENV.hvcm.url)
    );
  }

  //Used only in 2 places. App component (For initial app load & page refreshing) and login (policy search country code to update locale if necessary)
  //BehaviorSubject variable makes sure that all components react immediately if locale is updated (Particularly useful when user logs in with a different policy locale)
  //sessionStorage variable is needed because BehaviorSubject gets lost on page refresh
  setLocale(countryCode) {
    let currentLanguage = this.translateService.currentLang;
    //currentLanguage is mainly used to compare the locale they may have had during the login screens to the locale of the policy.
    //Particularly useful when keeping, for example, fr-fr locale, so that it doesn't always turn into en-FR
    if (currentLanguage) {
      if (countryCode == 'CA' || countryCode == 'en-CA') {
        this.locale.next('en-CA');
        sessionStorage.setItem('locale', 'en-CA');
      }
      else if (countryCode == 'US' || countryCode == 'en') {
        this.locale.next('en');
        sessionStorage.setItem('locale', 'en');
      }
      else if (countryCode == 'GB' || countryCode == 'en-GB') {
        this.locale.next('en-GB');  // United Kingdom
        sessionStorage.setItem('locale', 'en-GB');
      }
      else if (countryCode == 'DE' || countryCode?.includes('DE')) {
        if (currentLanguage == 'de-DE') {
          this.locale.next('de-DE');
          sessionStorage.setItem('locale', 'de-DE');
        } else {
          this.locale.next('en-DE'); // Germany
          sessionStorage.setItem('locale', 'en-DE');
        }
      }
      else if (countryCode == 'FR' || countryCode?.includes('FR')) {
        if (currentLanguage == 'fr-FR') {
          this.locale.next('fr-FR');
          sessionStorage.setItem('locale', 'fr-FR');
        } else {
          this.locale.next('en-FR'); // France
          sessionStorage.setItem('locale', 'en-FR');
        }
      }
      else if (countryCode == 'IT' || countryCode?.includes('IT')) {
        if (currentLanguage == 'it-IT') {
          this.locale.next('it-IT');
          sessionStorage.setItem('locale', 'it-IT');
        } else {
          this.locale.next('en-IT'); // Italy
          sessionStorage.setItem('locale', 'en-IT');
        }
      }
    }
    else {
      let locales = sessionStorage.getItem('locale');
      this.locale.next(locales);
    }
    this.getLocale().subscribe(data => { sessionStorage.setItem('locale', data); })
  }

  //Will always be in the form language-COUNTRYCODE, unless default, which is just 'en'
  //example - en-CA
  getLocale() {
    return this.locale;
  }

  getUserIDs(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_USER_IDS_FOR_POLICY}`, request);
  }

  getUserInfoListForPolicy(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_USER_INFO_LIST_FOR_POLICY}`, request);
  }

  updateUser(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.adminUpdateUser}`, request);
  }

  inviteUser(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.inviteUser}`, request);
  }

  deleteUser(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.adminDeleteUser}`, request);
  }

  deleteInvitedUser(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.deleteInvitedUser}`, request);
  }

  validateIDToken(idToken: any) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.RLS_Auto_Authentication}`, { token: idToken });
  }

  public getUsers(id: string) {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.getUserById}`;
    return this.http.get<any>(endpoint + `/${id}`, this.httpOptions);
  }


  public getUsersByUserId(id: string) {
    const endpoint = `${environment.baseUrl.api}${ApiEndpoints.GET_USERS_BY_USERID}`;
    return this.http.get<any>(endpoint + `/${id}`, this.httpOptions);
  }

  getUserAdminCount(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.adminUserCount}`, request);
  }

  updateUserDetails(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPDATE_USER_DETAILS}`, request);
  }

  decryptParams(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DESCRPT_PARAMS}`, request);
  }

  getTokens(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_ACCESS_TOKEN}`, request);
  }

  getMCInfo(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_MC_INFO}`, request);
  }
}
