export const environment = {
  production: true,
  hmr: false,
  baseUrl: {
    web: 'https://online-vulture.upscapital.com/',
    api: 'https://online-api-vulture.upscapital.com/',
    urlType: 'uat'
  },
  webjob: {
    url: 'https://webjobuatmailservice.scm.azurewebsites.net',
    username: '$WebJobUATMailService',
    env: 'UAT'
  },
  links: {
    techAgreement: 'https://upscapital.com/technology-agreement/',
    privacyNotice: 'https://upscapital.com/privacy-notice/',
    canadaprivacyNotice: 'https://upscapital.com/ca-en/privacy-notice/',
    canadaProductDisclosures: 'https://vulture.upscapital.com/ca-en/product-disclosures/',
    principlesofConduct: "https://urldefense.com/v3/__https:/5u84u.r.a.d.sendibm1.com/mk/cl/f/1FKr8TkIPnc74pCZgHHnr6I2YDkCcOIrT8bL8CT6AWZXgRjxR7B1tOlmV6_rnhfFxpB-1eAircs44q_x7T2zhPg6YTF1G_LR0A_dFVQj71tOkAvG2XC9T6My3HBKxqFY86MvNsPjV5A_v15CGVAK4B3GX0U2eMkvMu2Vzy0V4OFZuxYGrbI3eDg46B3sXZGiqHewGEgiFHAOpP6K2rkD3_9hKWLqVeMFalPmQ_Q__;!!AQegZw!PaelcAEQR10sdbj38MDu7tWNEDXLM4zhV1VhNifMw97PHJCunT12SFdRgA$",
    factSheet: "https://urldefense.com/v3/__https:/5u84u.r.a.d.sendibm1.com/mk/cl/f/aH9p8Y81SCMKF1zf5DNQeB5-ZBhPWqrxhVZXZ-H8xrqRWAeeI4oOMj6CuqzB-oOnZGU6wz_GrKScNP33tLQRtUdV4NKQ2YfU0TOHfGNvUjH2FzCoOE5zfiscZXWwHhdHk2FiEQcdMWkkj9kAEZJR5BV5tE4DuFoR4ms4NNm2LAyUKyvpZw8Z77NNW12aytr3N9uVaAbdxivjgXoTqfcSjps3NXOro3rP3g__;!!AQegZw!PaelcAEQR10sdbj38MDu7tWNEDXLM4zhV1VhNifMw97PHJCunT2Q2vkiGA$",
    deTechAgreementLink: 'https://www.insureshield.com/de/en/legal/technology-agreement.html',
    deDeTechAgreementLink: 'https://www.insureshield.com/de/de/legal/technology-agreement.html',
    itTechAgreementLink: 'https://www.insureshield.com/it/en/legal/technology-agreement.html',
    itItTechAgreementLink: 'https://www.insureshield.com/it/it/legal/technology-agreement.html',
    frTechAgreementLink: 'https://www.insureshield.com/fr/en/legal/technology-agreement.html',
    frFrTechAgreementLink: 'https://www.insureshield.com/fr/fr/legal/technology-agreement.html',
    ukComplints: 'https://vulture.upscapital.com/gb-en/complaints/',
    ukprivacyNotice: 'https://upscapital.com/gb-en/privacy-notice/',
    ukproductDisclosures: 'https://vulture.upscapital.com/gb-en/product-disclosures/',
    deComplints: 'https://www.insureshield.com/de/en/legal/complaints.html',
    deprivacyNotice: 'https://www.insureshield.com/de/en/legal/privacy-notice.html',
    deproductDisclosures: 'https://www.insureshield.com/de/en/legal/product-disclosure.html',
    deImpressum: 'https://www.insureshield.com/de/en/legal/impressum.html',
    itComplints: 'https://www.insureshield.com/it/en/legal/complaints.html',
    itprivacyNotice: 'https://www.insureshield.com/it/en/legal/privacy-notice.html',
    itproductDisclosures: 'https://www.insureshield.com/it/en/legal/product-disclosure.html',
    frComplints: 'https://www.insureshield.com/fr/en/legal/complaints.html',
    frprivacyNotice: 'https://www.insureshield.com/fr/en/legal/privacy-notice.html',
    frproductDisclosures: 'https://www.insureshield.com/fr/en/legal/product-disclosure.html',
    deDeComplints: 'https://www.insureshield.com/de/de/legal/complaints.html',
    deDeprivacyNotice: 'https://www.insureshield.com/de/de/legal/privacy-notice.html',
    deDeproductDisclosures: 'https://www.insureshield.com/de/de/legal/product-disclosure.html',
    deDeImpressum: 'https://vulture.upscapital.com/de-de/impressum/',
    determsOfuse: 'https://www.ups.com/de/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    deDetermsOfuse: 'https://www.ups.com/de/de/support/shipping-support/legal-terms-conditions.page',
    itItComplints: 'https://www.insureshield.com/it/it/legal/complaints.html',
    itItprivacyNotice: 'https://www.insureshield.com/it/it/legal/privacy-notice.html',
    itItproductDisclosures: 'https://www.insureshield.com/it/it/legal/product-disclosure.html',
    ittermsOfuse: 'https://www.ups.com/it/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    itIttermsOfuse: 'https://www.ups.com/it/it/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    frFrComplints: 'https://www.insureshield.com/fr/fr/legal/complaints.html',
    frFrprivacyNotice: 'https://www.insureshield.com/fr/fr/legal/privacy-notice.html',
    frFrproductDisclosures: 'https://www.insureshield.com/fr/fr/legal/product-disclosure.html',
    frtermsOfuse: 'https://www.ups.com/fr/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    frFrtermsOfuse: 'https://www.ups.com/fr/fr/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    MultiClaimDownloadForm: 'https://multiclaim-vulture.upscapital.com/Content/MultiClaim%20Template.xlsx',
  },
  tealiumEnv: 'qa',
  hpp: {
    paymentServiceUrl: 'https://www.chasepaymentechhostedpay-var.com/securepayments/a1/cc_collection.php',
    contentTemplateUrl: 'https://online-vulture.upscapital.com/assets/hpp/template-uat.html',
    cssUrl: 'https://online-vulture.upscapital.com/assets/hpp/styles.css',
    returnUrl: 'https://online-vulture.upscapital.com/billing/payment-method/return',
    cancelUrl: 'https://online-vulture.upscapital.com/billing/payment-method/add',
    baseUrl: 'https://online-vulture.upscapital.com/'
  },
  hpGuestUser: 'aHBHdWVzdFVzZXI=',
  smartUpload: {
    url: 'https://smrtupload-vulture.upscapital.com/?'
  },
  hvcm: {
    url: 'https://multiclaim-vulture.upscapital.com/',
    formLink: 'https://multiclaim-vulture.upscapital.com/Content/MultiClaim%20Template.xlsx'
  },
  tupss: {
    signin: 'https://login.microsoftonline.com/9d0c5465-d03b-48e5-85f5-11c82497e95b/oauth2/authorize?client_id=7685222b-413d-4b20-8bd4-3b3957af12fb&response_type=id_token&redirect_uri=https://online-vulture.upscapital.com/tfcp&scope=openid&state=12345&nonce=12345',
    terms: 'https://vulture.upscapital.com/wp-content/uploads/2023/10/ClaimProcessTermsandConditionsofService.pdf'
  },
  recaptcha: {
    siteKey: '6LfwkAUiAAAAAArZhkE0N1_0Bgo4jOU7HZsjoEhW',
    siteKeyV3: '6LfBOuYpAAAAAMF5oo1QSutDVt5hoEyQ3jpxZYaT',
    secretV3: '6LfBOuYpAAAAAFJpTT1kZyDmc2tWpLyURggfLQLO'
  },
  hardCodedValuesForLowerEnvironments: [
    {
      "Tracking Number": "1Z58941V0144026056",
      "Origin Scan Date": "7/5/2023",
      "Delivery Date": "7/7/2023",
      "Declared Value": "200.00",
      "DD Score": 148,
      "FIELD6": "",
      "Address 1": "8445 FORESTVIEW LN",
      "Address 2": "",
      "City": "OSSEO",
      "State": "MN",
      "Zip": "55369"
    },
    {
      "Tracking Number": "1Z58941V0144004856",
      "Origin Scan Date": "6/26/2023",
      // "Delivery Date": "6/28/2023",
      "Expected Delivery Date": "8/9/2023",
      "Declared Value": "150.00",
      "DD Score": 206,
      "FIELD6": "",
      "Address 1": "3920 PUCKETT CREEK XING",
      "Address 2": "",
      "City": "MURFREESBORO",
      "State": "TN",
      "Zip": "37128"
    },
    {
      "Tracking Number": "1Z58941V0244013335",
      "Origin Scan Date": "6/7/2023",
      "Delivery Date": "6/8/2023",
      "Declared Value": "500.00",
      "DD Score": 348,
      "FIELD6": "",
      "Address 1": "616 BRIGHT SUN DRIVE",
      "Address 2": "",
      "City": "LAUREL",
      "State": "MD",
      "Zip": "20707"
    },
    {
      "Tracking Number": "1Z58941V0244006978",
      "Origin Scan Date": "7/17/2023",
      "Delivery Date": "7/22/2023",
      "Declared Value": "300.00",
      "DD Score": 406,
      "FIELD6": "",
      "Address 1": "3000 GRAND AVE",
      "Address 2": "APT 815",
      "City": "DES MOINES",
      "State": "IA",
      "Zip": "50312"
    },
    {
      "Tracking Number": "1Z58941V0310682120",
      "Origin Scan Date": "6/29/2023",
      "Delivery Date": "N/A",
      "Declared Value": "258.64",
      "DD Score": 597,
      "FIELD6": "",
      "Address 1": "2740 SW MARTIN DOWNS BLVD",
      "Address 2": "UNIT 59",
      "City": "PALM CITY",
      "State": "FL",
      "Zip": "34990"
    },
    {
      "Tracking Number": "1Z58941V0310675763",
      "Origin Scan Date": "7/6/2023",
      "Delivery Date": "7/8/2023",
      "Declared Value": "392.99",
      "DD Score": 625,
      "FIELD6": "",
      "Address 1": "229 CHRYSTIE ST",
      "Address 2": "",
      "City": "NEW YORK",
      "State": "NY",
      "Zip": "10002"
    },
    {
      "Tracking Number": "1Z58941V0144032414",
      "Origin Scan Date": "6/26/2023",
      "Delivery Date": "N/A",
      "Declared Value": "450.50",
      "DD Score": 793,
      "FIELD6": "",
      "Address 1": "408 S LOCUST ST",
      "Address 2": "",
      "City": "GREENCASTLE",
      "State": "IN",
      "Zip": "46135"
    },
    {
      "Tracking Number": "1Z58941V0344023939",
      "Origin Scan Date": "6/20/2023",
      "Delivery Date": "6/22/2023",
      "Declared Value": "140.99",
      "DD Score": 808,
      "FIELD6": "",
      "Address 1": "2400 SPORTSMAN DR",
      "Address 2": "",
      "City": "PHENIX CITY",
      "State": "AL",
      "Zip": "36867"
    },
    {
      "Tracking Number": "1Z58941V0144019699",
      "Origin Scan Date": "7/18/2023",
      // "Delivery Date": "7/20/2023",
      "Expected Delivery Date": "8/9/2023",
      "Declared Value": "100.50",
      "DD Score": 974,
      "FIELD6": "",
      "Address 1": "100 CYPRESS PT",
      "Address 2": "",
      "City": "NEW BERN",
      "State": "NC",
      "Zip": "28560"
    },
    {
      "Tracking Number": "1Z58941V0210701208",
      "Origin Scan Date": "7/20/2023",
      "Delivery Date": "7/22/2023",
      "Declared Value": "200.95",
      "DD Score": 1000,
      "FIELD6": "",
      "Address 1": "33 BAY DR",
      "Address 2": "",
      "City": "SAINT CHARLES",
      "State": "MO",
      "Zip": "63301"
    },
    {
      "Tracking Number": "1Z58941V0210694842",
      "Origin Scan Date": "7/13/2023",
      "Delivery Date": "7/17/2023",
      "Declared Value": "225.00",
      "DD Score": 936,
      "FIELD6": "",
      "Address 1": "715 UNION AVE",
      "Address 2": "",
      "City": "SANTA MARIA",
      "State": "CA",
      "Zip": "93455"
    },
    {
      "Tracking Number": "1Z58941V0210688484",
      "Origin Scan Date": "6/6/2023",
      "Delivery Date": "7/17/2023",
      "Declared Value": "185.00",
      "DD Score": 811,
      "FIELD6": "",
      "Address 1": "7426 FISH POND RD",
      "Address 2": "",
      "City": "SIMS",
      "State": "NC",
      "Zip": "27880"
    },
    {
      "Tracking Number": "1Z58941V0344030298",
      "Origin Scan Date": "7/10/2023",
      "Delivery Date": "N/A",
      "Declared Value": "65.80",
      "DD Score": 597,
      "FIELD6": "",
      "Address 1": "1437 TOPAR AVE",
      "Address 2": "",
      "City": "LOS ALTOS",
      "State": "CA",
      "Zip": "94024"
    },
    {
      "Tracking Number": "1Z58941V0344036658",
      "Origin Scan Date": "7/20/2023",
      "Delivery Date": "7/21/2023",
      "Declared Value": "232.95",
      "DD Score": 1000,
      "FIELD6": "",
      "Address 1": "4517 TARDIVA DR",
      "Address 2": "",
      "City": "GARNER",
      "State": "NC",
      "Zip": "27529"
    },
    {
      "Tracking Number": "1ZHF27111204260109",
      "Origin Scan Date": "5/12/2024"
    },
    {
      "Tracking Number": "1ZHF27111270924652",
      "Origin Scan Date": "5/12/2024"
    },
    {
      "Tracking Number": "1ZMVARR60332937353",
      "Origin Scan Date": "5/12/2024"
    },
    {
      "Tracking Number": "1ZMVARR60399601358",
      "Origin Scan Date": "5/12/2024"
    },
    {
      "Tracking Number": "1Z5Y9F330391638040",
      "Origin Scan Date": "6/20/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F330396280935",
      "Origin Scan Date": "7/11/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1ZW9927X0346442717",
      "Origin Scan Date": "7/11/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F33YW99988650",
      "Origin Scan Date": "7/11/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F330396970645",
      "Origin Scan Date": "7/7/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F331294955916",
      "Origin Scan Date": "7/10/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F33YW92441332",
      "Origin Scan Date": "7/8/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F330296520141",
      "Origin Scan Date": "7/8/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F330395078111",
      "Origin Scan Date": "7/6/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F330394473409",
      "Origin Scan Date": "7/3/2023",
      "Declared Value": "294.78"
    },
    {
      "Tracking Number": "1Z5Y9F33YW91639925",
      "Origin Scan Date": "7/6/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F33YW90058886",
      "Origin Scan Date": "7/3/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1Z5Y9F33YW91180125",
      "Origin Scan Date": "7/6/2023",
      "Declared Value": "200"
    },
    {
      "Tracking Number": "1ZE678080390492273",
      "Origin Scan Date": "8/14/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZE678080391822002",
      "Origin Scan Date": "8/13/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZA7W3650329687361",
      "Origin Scan Date": "8/9/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZE678080394023781",
      "Origin Scan Date": "8/10/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZA7W3650329731188",
      "Origin Scan Date": "8/15/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZE678086747491922",
      "Origin Scan Date": "8/21/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZE678086747334137",
      "Origin Scan Date": "8/4/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZE678080398371095",
      "Origin Scan Date": "8/1/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZA7W3650329655412",
      "Origin Scan Date": "8/7/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZA7W3650329706312",
      "Origin Scan Date": "8/11/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZA7W3650329639172",
      "Origin Scan Date": "8/5/2023",
      "Declared Value": "600"
    },
    {
      "Tracking Number": "1ZY237X30378259575",
      "Origin Scan Date": "6/26/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7404247396133",
      "Origin Scan Date": "6/14/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1ZY237X30378241164",
      "Origin Scan Date": "6/12/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7404245085688",
      "Origin Scan Date": "6/15/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7400345102731",
      "Origin Scan Date": "6/16/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7400345068136",
      "Origin Scan Date": "6/14/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7404245062916",
      "Origin Scan Date": "6/14/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7400345063480",
      "Origin Scan Date": "6/14/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7404248927656",
      "Origin Scan Date": "6/15/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7404245086892",
      "Origin Scan Date": "6/15/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7400345115398",
      "Origin Scan Date": "6/19/2023",
      "Declared Value": "500"
    },
    {
      "Tracking Number": "1Z19A7400345107487",
      "Origin Scan Date": "6/19/2023",
      "Declared Value": "500"
    }
  ],
  roleLevelSecurity: {
    signin: 'https://login.microsoftonline.com/e7520e4d-d5a0-488d-9e9f-949faae7dce8/oauth2/authorize?client_id=6d71dfcc-3058-4691-b1f1-2c5a5e6af28b&response_type=id_token&redirect_uri=https://online-vulture.upscapital.com/internal&scope=openid&state=isop&nonce=170220'
  },
  createRequest: {
    applicationName: 'OnlinePortal',
    emailFor: "AuthorizeNewUser",
    emailForNewAdmin: "NewAdminRegistration",
    environment: "UAT"
  },
  rolename: {
    approleName: 'Administrator'
  },
  deliveryDefense: {
    url: 'https://vulture.deliverydefense.com/address-search-tool',
    timeoutUrl: 'https://vulture.deliverydefense.com/'
  },
  myChoice: {
    oAuthUrl: 'https://onlinetools.ups.com/security/v1/oauth/authorize',
    clientId: 'FNTvdm2HgivAqK9HGBGgmeulgGwlnJIAMqFuUg1heIVB4Fnl',
    redirectUri: 'ups-my-choice'
  },
  visibility: {
    contaxEnvironment: 'eu10'
  },
  prospect: {
    email: 'lmogos@ups.com'
  }
};
